import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

const SvgPath = ({scrollPos, ...props}) => {
  
  const [inViewRef, inView] = useInView({
    triggerOnce: true
  })
  //const pathRef = useRef()
  const [pathLength, setPathLength] = useState()

  const pathRef = useRef();
/*
  useEffect(() => {
    console.log(pathRef.current.getTotalLength());
  }, []);



  useEffect(() => {
    //console.log(pathRef.current.getTotalLength());

    const drawLength = pathLength * scrollPos;
    console.log('perct', scrollPos)
    console.log('pathref',pathRef.current.style.strokeDashoffset)
  
    // Draw in reverse
    pathRef.current.style.strokeDashoffset = - drawLength;
  }, [scrollPos]);

  useEffect(
    () => {
      if (pathRef.current) {
        setPathLength(pathRef.current.getTotalLength())
        console.log('length',pathRef.current.getTotalLength() )
        
        pathRef.current.style.strokeDasharray = '60';//'pathLength + ' ' + pathLength;

        // Offset the dashes so the it appears hidden entirely
        pathRef.current.style.strokeDashoffset = pathLength;
      }
    },
    [pathRef]
  )
*/
  return (

   
      <svg 
       // className={inView ? 'about-animated visible' : 'about-animated'}
       className='about-animated'
        viewBox="0 0 937 2758">

            <g id="rd3-prez" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.736909412" stroke-dasharray="20">
                <g id="triangle-grid-copy-23" transform="translate(-173.000000, -695.000000)" stroke="#FFE35F" stroke-width="10">
                    <path ref={pathRef} d="M1011.34491,699.930618 C465.548475,953.7025 200.467627,1218.78335 216.10237,1495.17316 C239.554484,1909.75788 1170.54499,1819.70709 1101.14607,2287.4288 C1054.88012,2599.24327 742.373676,2989.7156 163.626728,3458.84579" id="Path-2"></path>
                </g>
            </g>
        </svg>
    
   
  )
}


const Wrapper = styled.div`
  .about-animated {

    max-width: 100%;
   
    position: absolute;
    top: 100px;
    left:0;
    z-index: 0;
   
  }
  .about-animated-two {
    stroke-dasharray: ${(props) => props.pathLength};
    stroke-dashoffset: ${(props) => props.pathLength};
    max-width: 100%;
    height: auto;
    position: absolute;
    top: 10%;
    right: 0;
    z-index: 0;
    transform: rotate(-60deg);
  }
  .about-animated.visible, .about-animated-two.visible {
    animation: draw .2s linear forwards;
    animation-delay: 2s;
  }
  @keyframes draw {
    from {
      stroke-dashoffset: ${(props) => props.pathLength};
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  @media (min-width: 600px) {
    .about-animated {
      /*top: -5%;*/
    }
    .about-animated-two {
      top: -4%;
    }
  }`

export default SvgPath