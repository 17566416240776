import { render } from 'react-dom'
import * as THREE from 'three'
import React, { useState, useEffect, useMemo, useRef, Suspense } from 'react'
import { Canvas, Dom, extend, useUpdate, useLoader, useThree, useFrame } from 'react-three-fiber'
import { HTML, OrbitControls } from 'drei'
import { Block } from './blocks'
//import useYScroll from './useYScroll'
import lerp from 'lerp'
import { Shapes, Categories, Box } from './Home'
import SvgPath from './SvgPath'
import { Link, animateScroll as scroll } from "react-scroll";
import Reveal from 'react-reveal/Reveal';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'


import AnimatedCursor from './AnimatedCursor';


import state from './store'
import { useSpring } from '@react-spring/core'
import { a } from '@react-spring/three'


import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'

import logo from './assets/whitepine-logo.svg';
import topoImg from './assets/topo-test.png'
import owner from './assets/russell_herman_bw.jpg'


import Perlin from 'perlin.js'

import './styles.scss'


const dashpath = props => (
  <svg width="937px" height="2758px" viewBox="0 0 937 2758" {...props} >

    <g id="rd3-prez" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.736909412" stroke-dasharray="20">
        <g id="triangle-grid-copy-23" transform="translate(-173.000000, -695.000000)" stroke="#FFE35F" stroke-width="10">
            <path d="M1011.34491,699.930618 C465.548475,953.7025 200.467627,1218.78335 216.10237,1495.17316 C239.554484,1909.75788 1170.54499,1819.70709 1101.14607,2287.4288 C1054.88012,2599.24327 742.373676,2989.7156 163.626728,3458.84579" id="Path-2"></path>
        </g>
    </g>
</svg>
);

function Dolly({ carousel}) {
  const { size, viewport, gl, scene, camera, clock } = useThree()
  const aspect = size.height / viewport.height
  useFrame(() => {
    //if (state.top) {
      //camera.position.y = lerp(-state.top.current, -1, 0.05)

    const curY = carousel.current.position.y
    const curTop = state.top.current / aspect
    carousel.current.position.y = lerp(curY, (curTop / state.zoom) * 1, 0.1)
    const curR = carousel.current.rotation.y
   // carousel.current.rotation.y = lerp(curR, (-curTop / 60) * 1, 0.1)
  //  const calcPanel = Math.ceil(curTop/size.height);
  //  if (state.panel !== calcPanel) state.panel = calcPanel;
    
      //console.log('state.panel',state.panel)
   //}
   // state.camera.position.z = 50 + Math.sin(state.clock.getElapsedTime()) * 30
   // state.camera.updateProjectionMatrix()
  })

  return null
}


const Terrain = ({ ...props }) => {
  const [texture] = useLoader(THREE.TextureLoader, [topoImg])
  const { size, viewport, gl, scene, camera, clock } = useThree()

  const mesh = useUpdate(({ geometry }) => {
  //  console.log(geometry)
    Perlin.seed(Math.random())
    let pos = geometry.getAttribute('position')
    let pa = pos.array
    const hVerts = geometry.parameters.heightSegments + 1
    const wVerts = geometry.parameters.widthSegments + 1
    for (let j = 0; j < hVerts; j++) {
      for (let i = 0; i < wVerts; i++) {
        const ex = 1.1
        pa[3 * (j * wVerts + i) + 2] =
          (Perlin.simplex2(i / 100, j / 100) +
            Perlin.simplex2((i + 200) / 50, j / 50) * Math.pow(ex, 1) +
            Perlin.simplex2((i + 400) / 25, j / 25) * Math.pow(ex, 2) +
            Perlin.simplex2((i + 600) / 12.5, j / 12.5) * Math.pow(ex, 3) +
            +(Perlin.simplex2((i + 800) / 6.25, j / 6.25) * Math.pow(ex, 4))) /
          2
      }
    }

    pos.needsUpdate = true
  })

  // Raf loop
  useFrame(() => {
    //  mesh.current.rotation.z += 0.001;
  })

  return (
    <mesh ref={mesh} {...props} rotation={[0, 0, 0]} >
      <planeBufferGeometry attach="geometry" args={[viewport.width, viewport.height, 75, 75]} />
      <meshPhongMaterial attach="material" color={'white'} specular={'white'} shininess={3} smoothShading opacity={1} map={texture} side={THREE.DoubleSide} transparent={true} opacity={.08}/>
    </mesh>
  )
}

function HtmlContent({ className, style, children, portal }) {
  const { size } = useThree()
  return (
    <HTML
      portal={portal}
      style={{
        position: 'absolute',
        top: -size.height / 2,
        left: -size.width / 2,
        width: '100%',//size.width,
        height: size.height
      }}>
      <div id="lmth" className={className} style={style}>
        {children}
      </div>
    </HTML>
  )
}


function scrollToTop() {
  scroll.scrollToTop({
   // containerId: "scroller"
  })
}


function App() {
  const [events, setEvents] = useState()
  const domContent = useRef()
  const scrollArea = useRef()
  const landRef = useRef();

  const scrollPos = useRef();
  const { size, viewport, gl, scene, camera, clock } = useThree()
 //const [scrollPos, setScrollPos] = useState(0)
  const [scrollPer, setScrollPer] = useState(0)
  const [showSecondary, setShowSecondary] = useState(false)

  const [hideOnScroll, setHideOnScroll] = useState(true)
  const rendersCount = useRef(0)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
     // console.log(currPos)
     // console.log(document.body.scrollHeight)
      let per = Math.abs(currPos.y)/document.body.scrollHeight ;
   //   console.log('per', per)
      //setScrollPos(Math.abs(currPos.y));
      scrollPos.current = Math.abs(currPos.y)
   //   console.log('scrollpos', scrollPos.current)
      setScrollPer(per)
     
        if (isShow !== hideOnScroll) setHideOnScroll(isShow)
     
    },
    [hideOnScroll],
    false,
    false,
    300
  )


 // const onScroll = e => (state.top.current = e.target.scrollTop)

  const onScroll = (e) => {
    state.top.current = e.target.scrollTop;
   // setScrollPos(e.target.scrollTop)
    let per = e.target.scrollTop/e.target.scrollHeight;
  //  console.log('per', per)
    setScrollPer(per)
 
    if (e.target.scrollTop > 400){
      setShowSecondary(true)
    }else{
      setShowSecondary(false)
    }

  }
  //useEffect(() => void onScroll({ target: scrollArea.current }), [])
  //useEffect(() => void onScroll({ target: window }), [])

 
  return (
    <>
      {/*
      <Canvas
        colorManagement
        gl={{ alpha: true, antialias: true }}
        camera={{ position: [0, 0, 2000], fov: 25, near: 0.1, far: 10000 }}
        onCreated={({ gl, events }) => {
         // gl.setClearColor('#e6613e')
          gl.toneMappingExposure = 2.5
          gl.toneMappingWhitePoint = 1
          // Export canvas events, we will put them onto the scroll area
          setEvents(events)
        }}>
        
        <Suspense fallback={null}>
            <group
              ref={landRef}>
            <Terrain position={[40, 0, 0]} />
            <Terrain position={[0, -2350, 80]} />
            </group>
            <Dolly carousel={landRef} />
          </Suspense>
 

      </Canvas>
*/}
    
        <div className="containerX">
   
  
          {/*   <div className="content" ref={domContent} />
           <div style={{ height: `${state.pages * 100}vh` }} /> */}
           <div  className="content">

             <div className="grid-holder">
               <div className="column first"></div>
               <div className="column"></div>
               <div className="column"></div>
               <div className="column"></div>
             </div>
             <div className="path-holder" style={{ height: scrollPos.current  + 'px' }}>
             <SvgPath scrollPos={scrollPer}/>
             </div>
            <div className="menubar">
                <div className={`menu left showlogo`} >
                
                  <h1 className="logo-title" onClick={scrollToTop}><a href="#">White Pine Capital Management LLC</a></h1>
                </div>
                <div className="menu right">
      
                  <Link to="about"  smooth={true}><span>About</span></Link>
                  <Link to="contact"  smooth={true}><span>Contact Us</span></Link>
                </div>
            </div>
           

           
            <section className="wide-panel about" id="about">
           
              <div className="grid">
              <Reveal effect="fadeInUp">
                <div className="imgbox" >
                    <img src={owner} className="founder" alt="Russell Herman" />
                </div>
                </Reveal>
                <Reveal effect="fadeInUp">
                <div className="textbox">
                      <h2>White Pine Capital Management LLC is a private family office that focuses on investing 
            in early development companies in the technology, consumer, fintech and life sciences 
            sectors. </h2>
                   
                   
                </div>
                </Reveal>
              </div>
            </section>
            <section className="wide-panel all-text" >
            <p>Russell Herman, the founder of White Pine Capital Management LLC, is a former hedge 
fund manager who showed an early interest in the public markets when his grandfather 
taught him how to read the stock tables. After losing both his parents at a young age, 
Russell learned the value of perseverance and self-sufficiency as he invested and paid 
his way through college.</p>
                    <p>Russell graduated from University of Florida. At the age of 26, Russell started his own 
long/short equity hedge fund within Dawson-Herman Capital Management where the 
focus was technology, consumer, media, healthcare, industrial and commodity. With 
$10M of initial backing, Russell successfully grew the Southport Millennium family of 
funds to a total AUM of $3B. From 2000-2009, Russell served as the Chief Executive 
Officer, Chief Investment Officer, and Chairman of the Board of Directors of Dawson-
Herman Capital Management.</p>

                    <p>In 2012, Russell founded Herman Capital Management LLC, the predecessor to White 
Pine Capital Management LLC. White Pine Capital Management LLC serves as a family 
office. Other than spending time with his beloved family and various charitable 
organizations, you can usually find Russell in the surf, rolling on two wheels, or 
somewhere in the mountains.</p>
            </section>
            <Reveal effect="fadeInUp" duration={2000}>
              <div className="bottom-bkgd"></div>
            </Reveal>
            <section className="wide-panel footer" id="contact">
            <div className="trigrid">
                  <div className="triangle"></div>
                  <div className="triangle"></div>
                  <div className="triangle"></div>
                  <div className="triangle"></div>
                  <div className="triangle"></div>
                  <div className="triangle"></div>
                  <div className="triangle"></div>
                  <div className="triangle"></div>
                  <div className="triangle"></div>
                  <div className="triangle"></div>
                  <div className="triangle"></div>
                  <div className="triangle"></div>
                </div>
                <div className="grid">
                    <div className="footer-links">
                    <h3>Contact us to discuss new opportunities.</h3>
                    <a className="underline" href="mailTo:info@whitepinecap.com" target="_blank">info@whitepinecap.com</a>
                    </div>
                </div>
                
            </section>
           </div>
        </div>
      
      <AnimatedCursor />
    </>
  )
}

render(<App />, document.querySelector('#root'))
